import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable';

// import all the json files
import localeChi from '../locales/zh-HK.json';
import localeEng from '../locales/en.json';

Vue.use(VueI18n)

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    return {
        en: localeEng,
        'zh-HK': localeChi,
    };
}

export default createI18n({
    locale: 'zh-HK',
    fallbackLocale: 'zh-HK',
    messages: loadLocaleMessages(),
    dateTimeFormats: {
        'en': {
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            long: {
                year: 'numeric', month: 'short', day: 'numeric',
                weekday: 'short', hour: 'numeric', minute: 'numeric'
            }
        },
        'zh-HK': {
            short: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            long: {
                year: 'numeric', month: 'short', day: 'numeric',
                weekday: 'short', hour: 'numeric', minute: 'numeric'
            }
        }
    },
});
