
import { Component, Vue } from 'vue-property-decorator';

@Component
/** Error */
export default class Error extends Vue {
    /** Get Error Message */
    get error(): string {
        return this.$store.getters.error;
    }
    /** Mounted */
    mounted() {
        this.$store.dispatch('setOverlay', false);
    }
}
