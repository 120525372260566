
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
@Component
export default class NavListItem extends Vue {
  @Prop({ required: true, type: String, default: '' }) name!: string;
  @Prop({ required: false, type: String, default: '' }) linkName!: string;
  @Prop({ required: false, type: String, default: '' }) icon!: string;
  @Prop({ required: false, type: Object, default: () => {} }) activator!: any;
  @Prop({ required: false, type: Object, default: () => {} }) attrs!: any;
  @Prop({ required: false, type: String, default: '' }) activeClass!: string;
  @Prop({ required: false, type: Boolean, default: false }) disableBtn!: boolean;

  @Emit('btnClick')
  btnClick(name: string): string {
    return name;
  }
}
