var CryptoJS = require('crypto-js');
import Cookies from 'js-cookie';
import type StaffInterface from '@/interfaces/StaffInterface';
import ENV_SETTING from 'config';

const COOKIES_KEY = ENV_SETTING.ENV_COOKIES_KEY !== undefined &&
  ENV_SETTING.ENV_COOKIES_KEY !== null &&
  ENV_SETTING.ENV_COOKIES_KEY !== ''
  ? ENV_SETTING.ENV_COOKIES_KEY : 'QUOTATION_SYSTEM_CMS';

function encryptString(str: string): string {
  console.log(str)
  console.log(process.env.VUE_APP_API_ENCRYPTION_KEY)
  return CryptoJS.AES.encrypt(str, process.env.VUE_APP_API_ENCRYPTION_KEY);
}

function decryptString(str: any): string {
  if (str !== undefined && str !== null && str !== '') {
    const decrypt = CryptoJS.AES.decrypt(str, process.env.VUE_APP_API_ENCRYPTION_KEY);
    return decrypt.toString(CryptoJS.enc.Utf8);
  }

  return '{}';
}

function setStaffCookie(data: StaffInterface) {
  console.log("setStaffCookie")
  const copyData = Object.assign({}, JSON.parse(JSON.stringify(data)));
  console.log(JSON.stringify(copyData))
  const encryptData = encryptString(JSON.stringify(copyData));
  console.log(encryptData.toString())

  Cookies.set(COOKIES_KEY, encryptData.toString(), {
    path: '/',
    // expires: new Date(new Date().getTime() + 2 * 60 * 60 * 1000), // expires after 2 hour
    sameSite: 'Strict',
    secure: process.env.NODE_ENV === 'production',
  });
}

function getCurrentStaffData() {
  const decryptData = decryptString(getRawStaffData());
  return JSON.parse(decryptData ?? '{}');
}

function getRawStaffData() {
  return Cookies.get(COOKIES_KEY);
}

function removeStaffCookies() {
  Cookies.remove(COOKIES_KEY);
}

export {
  COOKIES_KEY,
  encryptString,
  decryptString,
  setStaffCookie,
  getCurrentStaffData,
  getRawStaffData,
  removeStaffCookies,
};
