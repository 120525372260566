/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
// Data formatter
import _Vue from 'vue';
import i18n from '@/plugins/i18n';

export function Formatter(Vue: typeof _Vue): void {
  Vue.prototype.$formatter = {
    // @keypress="$formatter.number($event)"
    number(event: any): boolean {
      // [0-9]
      if (event.charCode >= 48 && event.charCode <= 57) {
        return true;
      }

      return event.preventDefault();
    },
    floating(event: any): boolean {
      // [0-9][.][0-9]
      if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46) {
        return true;
      }

      return event.preventDefault();
    },
    alphaNumeric(event: any): boolean {
      // [0-9A-Za-z]

      if (
        (event.charCode >= 48 && event.charCode <= 57) ||
        (event.charCode >= 65 && event.charCode <= 90) ||
        (event.charCode >= 97 && event.charCode <= 122)
      ) {
        return true;
      }
      return event.preventDefault();
    },
    numberWithNegative(event: any): boolean {
      // [-][0-9]
      if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45) {
        return true;
      }

      return event.preventDefault();
    },
    floatingWithNegative(event: any): boolean {
      // [-][0-9][.][0-9]
      if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 || event.charCode === 45) {
        return true;
      }

      return event.preventDefault();
    },
    urlFilter(event: any): boolean {
      // A-Za-z0-9_-
      if (
        (event.charCode >= 48 && event.charCode <= 57) || // A-Z
        (event.charCode >= 65 && event.charCode <= 90) || // a-z
        (event.charCode >= 97 && event.charCode <= 122) || // 0-9
        event.charCode === 45 || // -
        event.charCode === 95 // _
      ) {
        return true;
      }

      return event.preventDefault();
    },
    customPageLinkFilter(event: any): boolean {
      // A-Za-z0-9- _ . ! ~ * ' ( )

      if (
        (event.charCode >= 48 && event.charCode <= 57) || // A-Z
        (event.charCode >= 65 && event.charCode <= 90) || // a-z
        (event.charCode >= 97 && event.charCode <= 122) || // 0-9
        event.charCode === 45 || // -
        event.charCode === 95 || // _
        event.charCode === 46 || // .
        event.charCode === 33 || // !
        event.charCode === 126 || // ~
        event.charCode === 42 || // *
        event.charCode === 39 || // '
        event.charCode === 40 || // (
        event.charCode === 41 // )
      ) {
        return true;
      }

      return event.preventDefault();
    },
    customFormatFilter(event: any, format: Array<any>): boolean {
      // format: [[20,40],[70]] -> (event.charCode >= 20 && event.charCode <= 40) || event.charCode === 70

      let result = false;
      for (let i = 0; i < format.length; i++) {
        if (format[i].length === 1) {
          result = result || event.charCode === format[i][0];
        } else if (format[i].length === 2) {
          result = result || (event.charCode >= format[i][0] && event.charCode <= format[i][1]);
        }
      }

      if (result) {
        return result;
      }

      return event.preventDefault();
    },
    removeInvalidCharacter(event: any): string {
      let result = '';
      // remove character except 0-9a-zA-Z _ and space

      let isInvalidChar = false;
      for (let i = 0; i < event.length; i++) {
        const code = event.charCodeAt(i);

        if (
          code === 32 ||
          code === 95 ||
          (code >= 48 && code < 57) ||
          (code >= 65 && code <= 90) ||
          (code >= 97 && code <= 122)
        ) {
          if (isInvalidChar) {
            // replace multiple invalid character to single underscore
            result += String.fromCharCode(95);
            isInvalidChar = false;
          }
          result += String.fromCharCode(code);
        } else {
          isInvalidChar = true;
        }
      }

      return result;
    },
    replaceInvalidCharacterToHyphen(s: string): string {
      // remove all character except chinese character and 0-9a-zA-Z
      // /[^\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9]/g
      const regex = new RegExp('[^\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9]', 'G');
      return s.replace(regex, '-');
    },
    formatUploadProgress(value: number, total: number): string {
      return ((value / total) * 100).toFixed(2);
    },
    formatDate(d: Date): string {
      return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${
        d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
      }`;
    },
    getDateStr(date: string): string {
      if (date !== undefined && date !== null && date !== '') {
        const item = date.split('T');
        return item.length > 0 ? date.split('T')[0] : date;
      }

      return date;
    },
    getTimeStr(date: string): string {
      if (date !== undefined && date !== null && date !== '') {
        const item = date.split('T');
        return item.length > 0 ? date.split('T')[1].replace('Z', '') : date;
      }

      return date;
    },
    getDateTimeStr(date: string, withSecond: Boolean = false): string {
      if (date !== undefined && date !== null && date !== '') {
        if (withSecond) {
          return `${date.split('T')[0]} ${date.split('T')[1].replace('Z', '')}`;
        } else {
          return `${date.split('T')[0]} ${date.split('T')[1].replace('Z', '').substring(0, 5)}`;
        }
      }

      return '';
    },
    convertStrToDate(dateStr: string): Date {
      return new Date(
        parseInt(dateStr.substring(0, 4)),
        parseInt(dateStr.substring(5, 7)) - 1,
        parseInt(dateStr.substring(8, 10)),
      );
    },
    dayFormat(e: string): string {
      return parseInt(e.substring(8, 10), 10).toString();
    },
    getNDayBeforeDate(date: Date | string, days: number, returnStr: boolean = true): Date | string {
      let tempDate = null;
      if (date instanceof Date) {
        tempDate = new Date(date.getTime());
      } else {
        tempDate = new Date(this.convertStrToDate(date));
      }

      tempDate.setDate(tempDate.getDate() - days);

      if (returnStr) {
        return this.formatDate(tempDate);
      }

      return tempDate;
    },
    getNDayAfterDate(date: Date | string, days: number, returnStr: boolean = true): Date | string {
      let tempDate = null;
      if (date instanceof Date) {
        tempDate = new Date(date.getTime());
      } else {
        tempDate = new Date(this.convertStrToDate(date));
      }

      tempDate.setDate(tempDate.getDate() + days);

      if (returnStr) {
        return this.formatDate(tempDate);
      }

      return tempDate;
    },
    displayWeekday(d: string): string {
      const weekday = ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'];
      if (d.length > 10) {
        return i18n.t(`day.${weekday[new Date(`${d.split('T')[0]}T12:00:00+08:00`).getDay()]}`);
      }

      return i18n.t(`day.${weekday[new Date(`${d}T12:00:00+08:00`).getDay()]}`);
    },
    calendarLang(currentLang: string): string {
      let lang = 'zh-hk';

      if (currentLang === 'en') {
        lang = 'en-us';
      }
      return lang;
    },
    convertStrToFloat(val: any, defaultValue: number = 0): number {
      if (val !== undefined && val !== null && val !== '' && !isNaN(val)) {
        return parseFloat(val);
      }

      return defaultValue;
    },
    convertStrToInt(val: any, defaultValue: number = 0): number {
      if (val !== undefined && val !== null && val !== '' && !isNaN(val)) {
        return parseInt(val);
      }

      return defaultValue;
    },
    formatMoney(price: any, decPlace: number = 1, showZeroDecPlace: boolean = false): string {
      const money = parseFloat(price);
      let d = 0;

      if (money % decPlace !== 0) {
        // contain decimal places e.g. 1.12
        d = decPlace;
      }
      let decSep = '.';
      let thouSep = ',';
      let sign = money < 0 ? '-' : '';
      var i: any = String(parseInt((i = Math.abs(Number(money) || 0).toFixed(d))));

      const strLen = i.toString().length;

      let count = 1;
      let returnStr = '';
      for (let k = strLen - 1; k >= 0; k--) {
        let character = i.charAt(k);
        returnStr = character + returnStr;
        if (count === 3) {
          if (k !== 0) {
            returnStr = thouSep + returnStr;
          }
          count = 1;
        } else {
          count++;
        }
      }

      let decStr = '';
      if (d) {
        let dec = Math.abs(money - i).toFixed(d);

        let searchDotIndex = dec.indexOf('.');
        if (searchDotIndex === -1) {
          dec = dec.slice(2);
        } else {
          dec = dec.slice(searchDotIndex + 1);
        }

        decStr = decSep + (dec === 'N' ? '0' : dec);
      } else {
        if (showZeroDecPlace) {
          decStr += decSep;
          for (let k = 0; k < decPlace; k++) {
            decStr += '0';
          }
        }
      }

      return sign + returnStr + decStr;
    },
    getEndTime(startTime: string, duration: number): string {
      // startTime format: HH:mm
      // duration -> in minutes

      let endTime = '00:00';

      const timeRegex = new RegExp('^(?:(0[0-9]|1[0-9]|2[0-3])):([0-5]{1}[0-9])$', 'i');
      // const timeRegex = /^(?:(0[0-9]|1[0-9]|2[0-3])):([0-5]{1}[0-9])$/i;
      if (timeRegex.test(startTime)) {
        const splitStartTime = startTime.split(':');
        const startHour = parseInt(splitStartTime[0]);
        const startMinute = parseInt(splitStartTime[1]);

        const addHour = parseInt((duration / 60).toString());
        const addMinutes = duration % 60;

        let newHour = startHour + addHour;
        let newMinute = startMinute + addMinutes;

        if (newMinute >= 60) {
          newMinute = 0;
          newHour++;
        }

        if (newHour >= 24) {
          newHour = newHour - 24;
        }

        endTime = `${newHour < 10 ? '0' + newHour : newHour}:${newMinute < 10 ? '0' + newMinute : newMinute}`;
      }

      return endTime;
    },
    calculateDuration(startTime: string, endTime: string): number {
      // startTime, endTime format: HH:mm

      let duration = 0;
      // /^(?:(0[0-9]|1[0-9]|2[0-3])):([0-5]{1}[0-9])$/i
      const timeRegex = new RegExp('^(?:(0[0-9]|1[0-9]|2[0-3])):([0-5]{1}[0-9])$', 'i');

      if (timeRegex.test(startTime) && timeRegex.test(endTime)) {
        const splitStartTime = startTime.split(':');
        let startHour = parseInt(splitStartTime[0]);
        let startMinute = parseInt(splitStartTime[1]);

        const splitEndTime = endTime.split(':');
        let endHour = parseInt(splitEndTime[0]);
        let endMinute = parseInt(splitEndTime[1]);

        if (endHour < startHour || (endHour === startHour && startMinute > endMinute)) {
          let tempHour = endHour;
          endHour = startHour;
          startHour = tempHour;

          let tempMin = endMinute;
          endMinute = startMinute;
          startMinute = tempMin;
        }

        if (endMinute < startMinute) {
          endMinute = endMinute + 60;
          endHour--;

          duration = endMinute - startMinute;
          duration = duration + (endHour - startHour) * 60;
        }

        duration = endMinute - startMinute;
        duration = duration + (endHour - startHour) * 60;
      }

      return duration;
    },
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $formatter: {
      number(event: any): boolean;
      floating(event: any): boolean;
      alphaNumeric(event: any): boolean;
      numberWithNegative(event: any): boolean;
      floatingWithNegative(event: any): boolean;
      urlFilter(event: any): boolean;
      customPageLinkFilter(event: any): boolean;
      customFormatFilter(event: any, format: Array<any>): boolean;
      removeInvalidCharacter(event: any): string;
      replaceInvalidCharacterToHyphen(s: string): string;
      formatUploadProgress(value: number, total: number): string;
      formatDate(d: Date): string;
      getDateStr(date: string): string;
      getTimeStr(date: string): string;
      getDateTimeStr(date: string, withSecond?: Boolean): string;
      convertStrToDate(dateStr: string): Date;
      dayFormat(e: string): string;
      getNDayBeforeDate(date: Date | string, days: number, returnStr?: boolean): Date | string;
      getNDayAfterDate(date: Date | string, days: number, returnStr?: boolean): Date | string;
      displayWeekday(d: string): string;
      calendarLang(currentLang: string): string;
      convertStrToFloat(val: any, defaultValue?: number): number;
      convertStrToInt(val: any, defaultValue?: number): number;
      formatMoney(money: number, decPlace?: number, showZeroDecPlace?: boolean): string;
      getEndTime(startTime: string, duration: number): string;
      calculateDuration(startTime: string, endTime: string): number;
    };
  }
}
