
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PageHeaderSlot extends Vue {
  // prop
  @Prop({ required: false, type: Boolean, default: false })
  showMiniBackButton: any;
  @Prop({ required: false, type: Boolean, default: false }) showBackButton: any;
  @Prop({ required: false, type: Boolean, default: false }) isLoading: any;

  // data
  pageTitle: string = '';

  // methods
  getPageTitle() {
    let result: any = '';

    if (this.$route.meta?.title !== undefined) {
      const titleArr = this.$route.meta?.title;
      if (titleArr.length > 1) {
        const keys = Object.keys(titleArr[1]);
        const obj: any = {};
        obj[keys[0]] = this.$t(titleArr[1][keys[0]]);
        result = this.$t(titleArr[0], obj);
      } else {
        result = this.$t(titleArr[0]);
      }
    }

    return result;
  }

  created() {
    this.pageTitle = this.getPageTitle();
  }
}
