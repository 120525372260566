import type {
    ActionContext,
    ActionTree,
    GetterTree,
    MutationTree,
    StoreOptions,
} from 'vuex';
import Vuex, { Store } from 'vuex';
import Vue from 'vue';

// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist';

// Modules
import ConfigModule from './ConfigModule';
import DataModule from './DataModule';

Vue.use(Vuex);

/** Root State Interface */
export interface RootState {
    /* + UI overlay */
    overlay: boolean;
    /* + Loading bar */
    loading: boolean;
    /** ProgressBar Percentage */
    progress: number;
    /** SnackBar Text */
    message?: string;
    /** Success SnackBar Text */
    successMessage?: MessageInterface;
    /** Error SnackBar Text */
    errorMessage?: MessageInterface;
    /** Warning SnackBar Text */
    warnMessage?: string;
    /* Upload Progress */
    uploadProgress: number;
}

/** State Default value */
const state: RootState = {
    overlay: false,
    loading: false,
    progress: 0,
    message: '',
    successMessage: { message: '', redirect: '', refresh: false, time: 3000 },
    errorMessage: { message: '', redirect: '', refresh: false, time: 3000 },
    warnMessage: undefined,
    uploadProgress: 0
};

/** Getters */
const getters: GetterTree<RootState, RootState> = {
    overlay: (s): boolean => s.overlay,
    loading: (s): boolean => s.loading,
    progress: (s): number => s.progress,
    message: (s): string | undefined => s.message,
    successMessage: (s): MessageInterface | undefined => s.successMessage,
    errorMessage: (s): MessageInterface | undefined => s.errorMessage,
    warnMessage: (s): string | undefined => s.warnMessage,
    uploadProgress: (s): number => s.uploadProgress
};

/** Mutations */
const mutations: MutationTree<RootState> = {
    /**
     * Store overlay
     *
     * @param s - Vuex state
     * @param display - Payload
     */
    storeOverlay(s, display: boolean) {
        s.overlay = display;
    },
    /**
     * Store loading
     *
     * @param s - Vuex state
     * @param display - Payload
     */
    storeLoading(s, display: boolean) {
        s.loading = display;
    },
    /**
     * Store progress
     *
     * @param s - Vuex state
     * @param progres - Spayload
     */
    storeProgress(s, progress: number) {
        s.progress = progress;
        s.loading = true;
    },
    /**
     * Store snackbar text
     *
     * @param s - Vuex state
     * @param message - Payload
     */
    storeMessage(s, message: string) {
        s.message = message;
    },
    /**
     * Store snackbar text
     *
     * @param s - Vuex state
     * @param message - Payload
     */
    storeSuccessMessage(s, message: MessageInterface) {
        if (message !== undefined && message !== null) {
            if (!(message.time !== undefined && message.time !== null && message.time !== 0)) {
                message.time = 3000;
            }
        }
        s.successMessage = message;
    },
    /**
     * Store snackbar text
     *
     * @param s - Vuex state
     * @param message - Payload
     */
    storeErrorMessage(s, message: MessageInterface) {
        if (message !== undefined && message !== null) {
            if (!(message.time !== undefined && message.time !== null && message.time !== 0)) {
                message.time = 3000;
            }
        }
        s.errorMessage = message;
    },
    /**
     * Store snackbar text
     *
     * @param s - Vuex state
     * @param message - Payload
     */
    storeWarnMessage(s, message: string) {
        s.warnMessage = message;
    },
    /**
     * Store Upload Progress
     *
     * @param s - Vuex state
     * @param progress - Payload
     */
    storeUploadProgress(s, progress: number) {
        s.uploadProgress = progress;
    },
};

/** Actions */
const actions: ActionTree<RootState, RootState> = {
    /**
     * UI overlay visibility
     *
     * @param context - Vuex Context
     * @param display - Visibility
     */
    setOverlay(
        context: ActionContext<RootState, RootState>,
        display: boolean = false
    ) {
        context.commit('storeOverlay', display);
    },
    /**
     * Loading visibility
     *
     * @param context - Vuex Context
     * @param display - Visibility
     */
    setLoading(
        context: ActionContext<RootState, RootState>,
        display: boolean = false
    ) {
        context.commit('storeLoading', display);
    },
    /**
     * Loading progress bar value
     *
     * @param context - Vuex Context
     * @param progress - Percentage(0~100)
     */
    setProgress(
        context: ActionContext<RootState, RootState>,
        progress: number = 0
    ) {
        context.commit('storeProgress', progress);
    },
    /**
     * Set snackbar message.
     *
     * @param context - Vuex Context
     * @param message - Message text
     */
    setMessage(context: ActionContext<RootState, RootState>, message?: string) {
        context.commit('storeMessage', message);
    },
    /**
     * Set snackbar message.
     *
     * @param context - Vuex Context
     * @param message - Message text
     */
    setSuccessMessage(context: ActionContext<RootState, RootState>, message?: MessageInterface) {
        context.commit('storeSuccessMessage', message);
    },
    /**
     * Set snackbar message.
     *
     * @param context - Vuex Context
     * @param message - Message text
     */
    setErrorMessage(context: ActionContext<RootState, RootState>, message?: MessageInterface) {
        context.commit('storeErrorMessage', message);
    },
    /**
     * Set snackbar message.
     *
     * @param context - Vuex Context
     * @param message - Message text
     */
    setWarnMessage(context: ActionContext<RootState, RootState>, message?: string) {
        context.commit('storeWarnMessage', message);
    },
    /**
 * Upload Progress
 *
 * @param context - Vuex Context
 * @param progress - upload progress
 */
    setUploadProgress(
        context: ActionContext<RootState, RootState>,
        progress: number = 0
    ) {
        context.commit('storeUploadProgress', progress);
    },
};

/** VuexStore */
const store: StoreOptions<RootState> = {
    // https://vuex.vuejs.org/guide/strict.html#development-vs-production
    state,
    getters,
    mutations,
    actions,
    modules: {
        ConfigModule,
        DataModule
    },
    plugins: [
        new VuexPersistence({
            key: 'vuex',
            storage: window.localStorage,
            modules: ['ConfigModule'],
        }).plugin,
        /*
    // store as session storage
    new VuexPersistence({
      key: import.meta.env.VITE_APP_WEBSTORAGE_NAMESPACE,
      storage: window.sessionStorage,
      modules: ['SomeModule'],
    }).plugin,
    // store as Indexed DB (using vuex-persist-indexeddb)
    createPersistedState({
      key: import.meta.env.VITE_APP_WEBSTORAGE_NAMESPACE,
      paths: ['SomeLargeModule'],
    }),
    */
    ],
};

interface MessageInterface {
    message: string;
    redirect: '';
    refresh: boolean;
    time?: number;
}

export default new Store<RootState>(store);
